import ArrowIcon from "../icons/arrow.icon";
import RenLink from "../shared/RenLink";
import {
  resolveSanityUrl,
  resolveSanityUrlFilepath,
} from "../../helpers/resolveSanityUrl";
import { limitText } from "../../helpers/limitText";
import { usePathname } from "next/navigation";

type RenbladCardLinkProps = {
  entry: {
    title: string;
    lede: string;
    seriesNumber: string;
  };
  index: number;
};

export const RenbladCardLink = ({
  entry,
  entry: { title, lede, seriesNumber },
  index,
}: RenbladCardLinkProps) => {
  const pathname = usePathname();
  const url = resolveSanityUrlFilepath(entry);
  const showProjectGoal = url && url.includes("forskning-og-utvikling");

  // const isRenbladSeries = router.pathname.startsWith("/renbladserie");
  const isRenbladSeries = pathname
    ? pathname.startsWith("/renbladserie")
    : false;
  const hoverClass = isRenbladSeries ? "" : " hover-focus";
  const cardClasses =
    "u-text--black c-renblad-card o-card-listing__item" +
    (index % 2 !== 0 ? " u-bg--lightgrey" : " u-bg--white") +
    hoverClass;

  return (
    <RenLink href={entry} classes={cardClasses}>
      <div className="c-renblad-card--spacing">
        {showProjectGoal && (
          <div className="c-renblad-card__header">
            <div>Prosjektets mål</div>
          </div>
        )}
        {seriesNumber && (
          <div className="c-renblad-card__header">{seriesNumber}</div>
        )}
        <div className="c-renblad-card__content">
          {title && (
            <h3 className=".u-text--black u-roboto500 u-fontLarge">{title}</h3>
          )}

          {lede && (
            <p className="c-renblad-card__lede">{limitText(lede, 35)}</p>
          )}
        </div>

        {resolveSanityUrl(entry) && (
          <div className="c-renblad-card__arrow">
            <ArrowIcon />
          </div>
        )}
      </div>
    </RenLink>
  );
};
